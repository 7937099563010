import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import { SERVICES_TO_REGIONS } from "@/game-lol/constants/constants.mjs";
import { SUPPORTED_QUEUES } from "@/game-lol/constants/season-review.mjs";
import LoLPlayerChampionSeasonStats from "@/game-lol/models/lol-player-champion-season-stats.mjs";
import LoLSummoner from "@/game-lol/models/lol-summoner.mjs";
import * as API from "@/game-lol/utils/api.mjs";
import { getLCUAvailable } from "@/game-lol/utils/lol-client-api.mjs";
import {
  extractRegionFromDerivedId,
  getDerivedId,
} from "@/game-lol/utils/util.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { devError } from "@/util/dev.mjs";

async function fetchData(params) {
  const [region, name, seasonStr] = params;

  if (!region || !name || !seasonStr) {
    devError("Missing region, name, or season.");
    return;
  }

  const derivedId = getDerivedId(region, name);

  const currentSummoner = readState.volatile.currentSummoner;
  const currentRegion =
    currentSummoner && extractRegionFromDerivedId(currentSummoner);

  const isUnsupportedRegion = !SERVICES_TO_REGIONS[region.toLowerCase()];

  const useLCU =
    isUnsupportedRegion && currentRegion === region && getLCUAvailable();

  if (isUnsupportedRegion && !useLCU) {
    devError("Can not retrieve profile info by any means.");
    return;
  }

  // Extend expiry time for own accounts.
  const isOwnAccount = Boolean(
    readState.settings.loggedInAccounts.lol[derivedId],
  );
  const expiryTime = !isOwnAccount
    ? null
    : Date.now() + 1000 * 60 * 60 * 24 * 365; // 1 year

  const profileOptions = {
    expiryTime,
    mergeFn: deepMerge,
  };
  const profilePath = ["lol", "profiles", derivedId];
  const profile = await getData(
    API.getSummoner({ region, name }),
    LoLSummoner,
    profilePath,
    profileOptions,
  );

  if (!profile?.id || profile instanceof Error) return;

  const stats = SUPPORTED_QUEUES.map((queue) => {
    return getData(
      API.getPlayerChampionSeasonStats({
        region: region.toUpperCase(),
        leagueProfileId: profile.id,
        queue: queue.enum,
        season: Number(seasonStr),
      }),
      LoLPlayerChampionSeasonStats,
      [
        "lol",
        "playerChampionSeasonStats",
        seasonStr,
        queue.stateKey(derivedId),
      ],
      { shouldFetchIfPathExists: false },
    );
  });

  return Promise.all(stats);
}

export default fetchData;
